<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" userFilter="analyticalResultsFilters"  :filterCollaborators="filterCollaborators" :filterAffairs="filterAffairs"  :dateRangeDefault="dateRangeDefault" :addButton="addButton" :searchButton="searchButton" :selectedRows="selectedRows" @setDates="setDates" @setCollaborators="setCollaborators" @setAffairs="setAffairs" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :isMobile="isMobile" :viewModeButton="viewModeButton" :viewMode="viewMode" @updateViewMode="updateViewMode")
      .w-100.d-flex.flex-column#scrollContent(style="overflow-x: scroll;" :style="'height: calc(100% - 58px);'")
          .text-center.flex-center.w-100(v-if='isLoadingAffairCostingsList || !headerLoaded || !dataLoaded')
              .loading-bg-inner
                  .loader
                      .outer
                      .middle
                      .inner
              .mt-5
                  br
                  br
                  br
                  | Chargement de la liste des données analytiques...
          sync-tree-grid#overviewtreegrid(v-else ref="overviewtreegrid" :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFilteredTree" :name="name" :headerData="headerData" @setSelectedRows="setSelectedRows" :commands="commands" :gridLines="gridLines" :pageOptions="pageOptions" :allowPaging="allowPaging" @actionBegin="actionBegin" @rowDataBound="rowDataBound")
  </template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatNumber,
  formatCurrency,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var weekday = require("dayjs/plugin/weekday");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);

import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import SyncGrid from "@/components/global/grid/Grid.vue";
import CellRendererColorCurrency from "@/components/global/ag-grid/CellRendererColorCurrency.vue";
import { documentApplicationType } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    HeaderGrid,
    SyncTreeGrid,
    SyncGrid,
  },
  data() {
    return {
      searchText: "",
      indexSearch: 0,
      name: "analytical-results",
      uid: "analyticalResults",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      selectedRowsIndexes: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("week")),
        new Date(dayjs().endOf("week")),
      ],
      collaboratorIds: [],
      affairIds: [],
      currentDate: new Date(),
      currentView: "TimelineWeek",
      viewModeButton: true,
      viewMode: "compare",
      dateRanger: true,
      filterCollaborators: true,
      filterAffairs: true,
      addButton: false,
      searchButton: true,
      searchPlaceholder: "Rechercher une écriture",
      allowFiltering: true,
      allowPaging: false,
      dataLoaded: true,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showDeleteConfirmDialog: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "",
      },
      allowPaging: false,
      commands: [
        {
          type: "addRow",
          buttonOption: {
            cssClass: "e-flat e-addbutton",
            iconCss: "e-plus e-icons",
          },
        },
        {
          type: "duplicate",
          buttonOption: {
            cssClass: "e-flat e-duplicatebutton",
            iconCss: "e-copy e-icons",
          },
        },
        {
          type: "Delete",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-save e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      groupSettings: {
        captionTemplate: "captionTemplate",
        columns: [],
      },
      sortOptions: {
        columns: [{ field: "displayLabel", direction: "Descending" }],
      },
      isMobile: checkIfMobile(),

      parentIdMapping: "parentAccountNumber",
      idMapping: "accountNumber",
      hasChildMapping: "parentAccountNumber",
      treeColumnIndex: 1,
      allowGrouping: true,
      gridLines: "Both",
      pageOptions: null,
      colorCurrencyTemplate() {
        return {
          template: CellRendererColorCurrency,
        };
      },
    };
  },
  async activated() {
    this.setHeaderData();
    this.indexSearch++;
    this.headerLoaded = true;
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "affairCostingsList",
      "affairCostingsListByAffair",
      "isLoadingAffairCostingsList",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "workspaceSelected",
      "userParametersList",
    ]),
    analyticsSettingsListMapped() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataOrigine() {
      return this.affairCostingsList.filter((elem) => {
        if (
          (this.affairIds && this.affairIds.length > 0) ||
          (this.collaboratorIds && this.collaboratorIds.length > 0)
        ) {
          if (
            this.affairIds &&
            this.affairIds.length > 0 &&
            this.collaboratorIds &&
            this.collaboratorIds.length > 0
          ) {
            return (
              this.affairIds.includes(elem.affairId) &&
              this.collaboratorIds.includes(elem.collaboratorId)
            );
          } else {
            return (
              this.affairIds.includes(elem.affairId) ||
              this.collaboratorIds.includes(elem.collaboratorId)
            );
          }
        } else {
          return true;
        }
      });
    },
    dataFilteredTree() {
      let dataAnalytical = [
        {
          id: null,
          directionType: 1,
          accountNumber: "VE",
          parentAccountNumber: null,
          displayLabel: "VENTES",
          percent: 100,
          amount: 0,
          affairId: null,
          missionId: null,
          documentPath: null,
          hasChild: true,
        },
        {
          id: null,
          directionType: 2,
          accountNumber: "CH",
          parentAccountNumber: null,
          displayLabel: "CHARGES",
          percent: 100,
          amount: 0,
          affairId: null,
          missionId: null,
          documentPath: null,
          hasChild: true,
        },
      ];
      /****** RAPPEL ******
       * SourceType = 1 >> Réél
       * SourceType = 2 >> Prévu
       * DirectionType = 1 >> Entrée
       * DirectionType = 2 >> Sortie
       ********************/

      /******** VENTES  **************
       * On récupère les donnés de vente --- DocumentApplicationType = 3 (Factures)
       *  ****************************/
      let dataAffairCostingsSales = this.dataOrigine.filter(
        (elem) => elem.directionType == 1 && elem.documentApplicationType == 3
      );
      const sumSalesForecast = dataAffairCostingsSales
        .filter((elem) => elem.sourceType == 2)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      const sumSalesCounted = dataAffairCostingsSales
        .filter((elem) => elem.sourceType == 1)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      let dataAffairCostingsSalesGroupedByAnalytics = dataAffairCostingsSales
        .reduce((acc, obj) => {
          // On crée un object par code analytique
          let findIndex = acc.findIndex(
            (el) => el.analyticsSettingId == obj.analyticsSettingId
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              ...obj,
              quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
              amountForecast:
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
              percentForecast: 100,
              quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
              amountCounted:
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
              percentCounted: 100,
            });
          } else {
            // On additionne
            acc[findIndex].amountCounted +=
              obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityCounted +=
              obj.sourceType == 1 ? obj.quantity : 0;
            acc[findIndex].amountForecast +=
              obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityForecast +=
              obj.sourceType == 2 ? obj.quantity : 0;
          }
          return acc;
        }, [])
        .map((affairCosting) => {
          return {
            id: affairCosting.id,
            accountNumber: "VE_AN_" + affairCosting.analyticsSettingId,
            parentAccountNumber: "VE",
            displayLabel: this.analyticsSettingsList.find(
              (el) => el.id == affairCosting.analyticsSettingId
            )
              ? this.analyticsSettingsList.find(
                  (el) => el.id == affairCosting.analyticsSettingId
                ).label
              : "Autres",
            affairId: affairCosting.affairId,
            missionId: affairCosting.missionId,
            percentCounted: Number(
              (affairCosting.amountCounted / sumSalesCounted) * 100
            ),
            amountCounted: affairCosting.amountCounted,
            quantityCounted: affairCosting.quantityCounted,
            percentForecast: Number(
              (affairCosting.amountForecast / sumSalesForecast) * 100
            ),
            amountForecast: affairCosting.amountForecast,
            quantityForecast: affairCosting.quantityForecast,
            documentPath: null,
            documentApplicationType: 1,
            hasChild: false,
          };
        })
        .sort(this.comparer);
      // On ajoute le détail par factute
      let dataAffairCostingsSalesGroupedByInvoice = dataAffairCostingsSales
        .reduce((acc, obj) => {
          // On crée un object par code analytique
          let findIndex = acc.findIndex(
            (el) =>
              el.documentId == obj.documentId &&
              el.analyticsSettingId == obj.analyticsSettingId
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              ...obj,
              quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
              amountForecast:
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
              percentForecast: 100,
              quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
              amountCounted:
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
              percentCounted: 100,
            });
          } else {
            // On additionne
            acc[findIndex].amountCounted +=
              obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityCounted +=
              obj.sourceType == 1 ? obj.quantity : 0;
            acc[findIndex].amountForecast +=
              obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityForecast +=
              obj.sourceType == 2 ? obj.quantity : 0;
          }
          return acc;
        }, [])
        .map((affairCosting) => {
          return {
            id: affairCosting.id,
            accountNumber:
              "VE_AN_" +
              affairCosting.analyticsSettingId +
              "_DOC_" +
              affairCosting.documentId,
            parentAccountNumber: "VE_AN_" + affairCosting.analyticsSettingId,
            displayLabel: affairCosting.label,
            affairId: affairCosting.affairId,
            missionId: affairCosting.missionId,
            percentCounted: Number(
              (affairCosting.amountCounted / sumSalesCounted) * 100
            ),
            amountCounted: affairCosting.amountCounted,
            quantityCounted: affairCosting.quantityCounted,
            percentForecast: Number(
              (affairCosting.amountForecast / sumSalesForecast) * 100
            ),
            amountForecast: affairCosting.amountForecast,
            quantityForecast: affairCosting.quantityForecast,
            documentPath: affairCosting.documentPath,
            documentApplicationType: affairCosting.documentApplicationType,
            hasChild: false,
          };
        })
        .sort(this.comparer);
      /******** END ******************/

      /******** CHARGES > PRODUCTION ******
       * On récupère les donnés d'achat --- DocumentApplicationType = 12 (Reporting)
       *  ****************************/
      let dataAffairCostingsOperate = this.dataOrigine.filter(
        (elem) => elem.directionType == 2 && elem.documentApplicationType == 12
      );
      const sumProductionForecast = dataAffairCostingsOperate
        .filter((elem) => elem.sourceType == 2)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      const sumProductionCounted = dataAffairCostingsOperate
        .filter((elem) => elem.sourceType == 1)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      let dataAffairCostingsChargesGroupedByMission = dataAffairCostingsOperate
        .reduce((acc, obj) => {
          // On crée un object par mission
          let findIndex = acc.findIndex((el) => el.missionId == obj.missionId);
          if (findIndex < 0) {
            // On instancie
            acc.push({
              ...obj,
              quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
              amountForecast:
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
              percentForecast: 100,
              quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
              amountCounted:
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
              percentCounted: 100,
            });
          } else {
            // On additionne
            acc[findIndex].amountCounted +=
              obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityCounted +=
              obj.sourceType == 1 ? obj.quantity : 0;
            acc[findIndex].amountForecast +=
              obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityForecast +=
              obj.sourceType == 2 ? obj.quantity : 0;
          }
          return acc;
        }, [])
        .map((affairCosting) => {
          return {
            id: affairCosting.id,
            accountNumber: "CH_PD_TK_" + affairCosting.missionId,
            parentAccountNumber: "CH_PD",
            displayLabel: this.missionsList.find(
              (el) => el.id == affairCosting.missionId
            )
              ? this.missionsList.find((el) => el.id == affairCosting.missionId)
                  .label
              : "Sans mission",
            affairId: affairCosting.affairId,
            missionId: affairCosting.missionId,
            percentCounted: Number(
              (affairCosting.amountCounted / sumProductionCounted) * 100
            ),
            amountCounted: affairCosting.amountCounted,
            quantityCounted: affairCosting.quantityCounted,
            percentForecast: Number(
              (affairCosting.amountForecast / sumProductionForecast) * 100
            ),
            amountForecast: affairCosting.amountForecast,
            quantityForecast: affairCosting.quantityForecast,
            documentPath: affairCosting.documentPath,
            documentApplicationType: affairCosting.documentApplicationType,
            hasChild: false,
          };
        })
        .sort(this.comparer);
      if (dataAffairCostingsChargesGroupedByMission.length > 0) {
        let amountReportingByMissionCounted = 0;
        let quantityReportingByMissionCounted = 0;
        let amountReportingByMissionForecast = 0;
        let quantityReportingByMissionForecast = 0;
        for (
          var i = 0;
          i < dataAffairCostingsChargesGroupedByMission.length;
          i++
        ) {
          amountReportingByMissionCounted +=
            dataAffairCostingsChargesGroupedByMission[i].amountCounted;
          quantityReportingByMissionCounted +=
            dataAffairCostingsChargesGroupedByMission[i].quantityCounted;
          amountReportingByMissionForecast +=
            dataAffairCostingsChargesGroupedByMission[i].amountForecast;
          quantityReportingByMissionForecast +=
            dataAffairCostingsChargesGroupedByMission[i].quantityForecast;
        }
        dataAnalytical.push({
          id: null,
          directionType: 1,
          accountNumber: "CH_PD",
          parentAccountNumber: "CH",
          displayLabel: "Production",
          percentCounted: 100,
          amountCounted: amountReportingByMissionCounted,
          quantityCounted: quantityReportingByMissionCounted,
          percentForecast: 100,
          amountForecast: amountReportingByMissionForecast,
          quantityForecast: quantityReportingByMissionForecast,
          affairId: null,
          missionId: null,
          documentPath: null,
          documentApplicationType: 1,
          hasChild: true,
        });
      }
      /******** END ******************/

      /******** CHARGES > AUTRES ******
       * On récupère les donnés d'achat --- DocumentApplicationType = 1 (Aucun)
       *  ****************************/
      let dataAffairCostingsAutres = this.dataOrigine.filter(
        (elem) => elem.directionType == 2 && elem.documentApplicationType == 1
      );
      const sumAutresForecast = dataAffairCostingsAutres
        .filter((elem) => elem.sourceType == 2)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      const sumAutresCounted = dataAffairCostingsAutres
        .filter((elem) => elem.sourceType == 1)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      let dataAffairCostingsAutresGroupedByAnalytics = dataAffairCostingsAutres
        .reduce((acc, obj) => {
          // On crée un object par code analytique
          let findIndex = acc.findIndex(
            (el) => el.analyticsSettingId == obj.analyticsSettingId
          );
          if (findIndex < 0) {
            // On instancie
            acc.push({
              ...obj,
              quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
              amountForecast:
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
              percentForecast: 100,
              quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
              amountCounted:
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
              percentCounted: 100,
            });
          } else {
            // On additionne
            acc[findIndex].amountCounted +=
              obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityCounted +=
              obj.sourceType == 1 ? obj.quantity : 0;
            acc[findIndex].amountForecast +=
              obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
            acc[findIndex].quantityForecast +=
              obj.sourceType == 2 ? obj.quantity : 0;
          }
          return acc;
        }, [])
        .map((affairCosting) => {
          return {
            id: affairCosting.id,
            accountNumber: "CH_AU_AN_" + affairCosting.analyticsSettingId,
            parentAccountNumber: "CH_AU",
            displayLabel: this.analyticsSettingsList.find(
              (el) => el.id == affairCosting.analyticsSettingId
            )
              ? this.analyticsSettingsList.find(
                  (el) => el.id == affairCosting.analyticsSettingId
                ).label
              : "Autres",
            affairId: affairCosting.affairId,
            missionId: affairCosting.missionId,
            percentCounted: Number(
              (affairCosting.amountCounted / sumAutresCounted) * 100
            ),
            amountCounted: affairCosting.amountCounted,
            quantityCounted: affairCosting.quantityCounted,
            percentForecast: Number(
              (affairCosting.amountForecast / sumAutresForecast) * 100
            ),
            amountForecast: affairCosting.amountForecast,
            quantityForecast: affairCosting.quantityForecast,
            documentPath: null,
            documentApplicationType: 9,
            hasChild: false,
          };
        })
        .sort(this.comparer);

      if (dataAffairCostingsAutresGroupedByAnalytics.length > 0) {
        let amountAutresByAnalyticsCounted = 0;
        let quantityAutresByAnalyticsCounted = 0;
        let amountAutresByAnalyticsForecast = 0;
        let quantityAutresByAnalyticsForecast = 0;
        for (
          var i = 0;
          i < dataAffairCostingsAutresGroupedByAnalytics.length;
          i++
        ) {
          amountAutresByAnalyticsCounted +=
            dataAffairCostingsAutresGroupedByAnalytics[i].amountCounted;
          quantityAutresByAnalyticsCounted +=
            dataAffairCostingsAutresGroupedByAnalytics[i].quantityCounted;
          amountAutresByAnalyticsForecast +=
            dataAffairCostingsAutresGroupedByAnalytics[i].amountForecast;
          quantityAutresByAnalyticsForecast +=
            dataAffairCostingsAutresGroupedByAnalytics[i].quantityForecast;
        }
        dataAnalytical.push({
          id: null,
          directionType: 2,
          accountNumber: "CH_AU",
          parentAccountNumber: "CH",
          displayLabel: "Autres",
          percentCounted: 100,
          amountCounted: amountAutresByAnalyticsCounted,
          quantityCounted: quantityAutresByAnalyticsCounted,
          percentForecast: 100,
          amountForecast: amountAutresByAnalyticsForecast,
          quantityForecast: quantityAutresByAnalyticsForecast,
          affairId: null,
          missionId: null,
          documentPath: null,
          documentApplicationType: 9,
          hasChild: true,
        });
      }

      /******** CHARGES > ACHATS ******
       * On récupère les donnés d'achat --- DocumentApplicationType = 9 (Factures fournisseur)
       *  ****************************/
      let dataAffairCostingsPurchase = this.dataOrigine.filter(
        (elem) => elem.directionType == 2 && elem.documentApplicationType == 9
      );
      const sumPurchaseForecast = dataAffairCostingsPurchase
        .filter((elem) => elem.sourceType == 2)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      const sumPurchaseCounted = dataAffairCostingsPurchase
        .filter((elem) => elem.sourceType == 1)
        .reduce((acc, obj) => acc + obj.amountUnit * obj.quantity, 0);
      let dataAffairCostingsPurchaseGroupedByAnalytics =
        dataAffairCostingsPurchase
          .reduce((acc, obj) => {
            // On crée un object par code analytique
            let findIndex = acc.findIndex(
              (el) => el.analyticsSettingId == obj.analyticsSettingId
            );
            if (findIndex < 0) {
              // On instancie
              acc.push({
                ...obj,
                quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
                amountForecast:
                  obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
                percentForecast: 100,
                quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
                amountCounted:
                  obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
                percentCounted: 100,
              });
            } else {
              // On additionne
              acc[findIndex].amountCounted +=
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
              acc[findIndex].quantityCounted +=
                obj.sourceType == 1 ? obj.quantity : 0;
              acc[findIndex].amountForecast +=
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
              acc[findIndex].quantityForecast +=
                obj.sourceType == 2 ? obj.quantity : 0;
            }
            return acc;
          }, [])
          .map((affairCosting) => {
            return {
              id: affairCosting.id,
              accountNumber: "CH_AC_AN_" + affairCosting.analyticsSettingId,
              parentAccountNumber: "CH_AC",
              displayLabel: this.analyticsSettingsList.find(
                (el) => el.id == affairCosting.analyticsSettingId
              )
                ? this.analyticsSettingsList.find(
                    (el) => el.id == affairCosting.analyticsSettingId
                  ).label
                : "Autres",
              affairId: affairCosting.affairId,
              missionId: affairCosting.missionId,
              percentCounted: Number(
                (affairCosting.amountCounted / sumPurchaseCounted) * 100
              ),
              amountCounted: affairCosting.amountCounted,
              quantityCounted: affairCosting.quantityCounted,
              percentForecast: Number(
                (affairCosting.amountForecast / sumPurchaseForecast) * 100
              ),
              amountForecast: affairCosting.amountForecast,
              quantityForecast: affairCosting.quantityForecast,
              documentPath: null,
              documentApplicationType: 9,
              hasChild: false,
            };
          })
          .sort(this.comparer);

      if (dataAffairCostingsPurchaseGroupedByAnalytics.length > 0) {
        let amountReportingByAnalyticsCounted = 0;
        let quantityReportingByAnalyticsCounted = 0;
        let amountReportingByAnalyticsForecast = 0;
        let quantityReportingByAnalyticsForecast = 0;
        for (
          var i = 0;
          i < dataAffairCostingsPurchaseGroupedByAnalytics.length;
          i++
        ) {
          amountReportingByAnalyticsCounted +=
            dataAffairCostingsPurchaseGroupedByAnalytics[i].amountCounted;
          quantityReportingByAnalyticsCounted +=
            dataAffairCostingsPurchaseGroupedByAnalytics[i].quantityCounted;
          amountReportingByAnalyticsForecast +=
            dataAffairCostingsPurchaseGroupedByAnalytics[i].amountForecast;
          quantityReportingByAnalyticsForecast +=
            dataAffairCostingsPurchaseGroupedByAnalytics[i].quantityForecast;
        }
        dataAnalytical.push({
          id: null,
          directionType: 2,
          accountNumber: "CH_AC",
          parentAccountNumber: "CH",
          displayLabel: "Achats",
          percentCounted: 100,
          amountCounted: amountReportingByAnalyticsCounted,
          quantityCounted: quantityReportingByAnalyticsCounted,
          percentForecast: 100,
          amountForecast: amountReportingByAnalyticsForecast,
          quantityForecast: quantityReportingByAnalyticsForecast,
          affairId: null,
          missionId: null,
          documentPath: null,
          documentApplicationType: 9,
          hasChild: true,
        });
      }

      // On ajoute le détail par factute
      let dataAffairCostingsPurchaseGroupedByInvoice =
        dataAffairCostingsPurchase
          .reduce((acc, obj) => {
            // On crée un object par code analytique
            let findIndex = acc.findIndex(
              (el) =>
                el.documentId == obj.documentId &&
                el.analyticsSettingId == obj.analyticsSettingId
            );
            if (findIndex < 0) {
              // On instancie
              acc.push({
                ...obj,
                quantityForecast: obj.sourceType == 2 ? obj.quantity : 0,
                amountForecast:
                  obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0,
                percentForecast: 100,
                quantityCounted: obj.sourceType == 1 ? obj.quantity : 0,
                amountCounted:
                  obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0,
                percentCounted: 100,
              });
            } else {
              // On additionne
              acc[findIndex].amountCounted +=
                obj.sourceType == 1 ? obj.amountUnit * obj.quantity : 0;
              acc[findIndex].quantityCounted +=
                obj.sourceType == 1 ? obj.quantity : 0;
              acc[findIndex].amountForecast +=
                obj.sourceType == 2 ? obj.amountUnit * obj.quantity : 0;
              acc[findIndex].quantityForecast +=
                obj.sourceType == 2 ? obj.quantity : 0;
            }
            return acc;
          }, [])
          .map((affairCosting) => {
            return {
              id: affairCosting.id,
              accountNumber:
                "CH_AC_AN_" +
                affairCosting.analyticsSettingId +
                "_DOC_" +
                affairCosting.documentId,
              parentAccountNumber:
                "CH_AC_AN_" + affairCosting.analyticsSettingId,
              displayLabel: affairCosting.label,
              affairId: affairCosting.affairId,
              missionId: affairCosting.missionId,
              percentForecast: Number(
                (affairCosting.amountForecast / sumPurchaseForecast) * 100
              ),
              amountForecast: affairCosting.amountForecast,
              quantityForecast: affairCosting.quantityForecast,
              percentCounted: Number(
                (affairCosting.amountCounted / sumPurchaseCounted) * 100
              ),
              amountCounted: affairCosting.amountCounted,
              quantityCounted: affairCosting.quantityCounted,
              documentPath: affairCosting.documentPath,
              documentApplicationType: affairCosting.documentApplicationType,
              hasChild: false,
            };
          })
          .sort(this.comparer);
      /******** END ******************/

      // On ajoute la ligne marge brut
      dataAnalytical.push({
        id: null,
        directionType: 2,
        accountNumber: "MB",
        parentAccountNumber: null,
        displayLabel: "MARGE BRUT",
        percentForecast: Number(
          ((sumSalesForecast -
            sumProductionForecast -
            sumAutresForecast -
            sumPurchaseForecast) /
            sumSalesForecast) *
            100
        ),
        quantityForecast: 0,
        amountForecast: Number(
          sumSalesForecast -
            sumProductionForecast -
            sumAutresForecast -
            sumPurchaseForecast
        ),
        percentCounted: Number(
          ((sumSalesCounted -
            sumProductionCounted -
            sumAutresCounted -
            sumPurchaseCounted) /
            sumSalesCounted) *
            100
        ),
        quantityCounted: 0,
        amountCounted: Number(
          sumSalesCounted -
            sumProductionCounted -
            sumAutresCounted -
            sumPurchaseCounted
        ),
        affairId: null,
        missionId: null,
        documentPath: null,
        documentApplicationType: 1,
        hasChild: true,
      });

      let result = dataAnalytical
        .concat(dataAffairCostingsSalesGroupedByAnalytics)
        .concat(dataAffairCostingsSalesGroupedByInvoice)
        .concat(dataAffairCostingsPurchaseGroupedByAnalytics)
        .concat(dataAffairCostingsAutresGroupedByAnalytics)
        .concat(dataAffairCostingsPurchaseGroupedByInvoice)
        .concat(dataAffairCostingsChargesGroupedByMission)
        .map((elem) => {
          let childElem = dataAnalytical
            .concat(dataAffairCostingsSalesGroupedByAnalytics)
            .concat(dataAffairCostingsSalesGroupedByInvoice)
            .concat(dataAffairCostingsPurchaseGroupedByAnalytics)
            .concat(dataAffairCostingsAutresGroupedByAnalytics)
            .concat(dataAffairCostingsPurchaseGroupedByInvoice)
            .concat(dataAffairCostingsChargesGroupedByMission)
            .filter(
              (childElem) => childElem.parentAccountNumber == elem.accountNumber
            );

          let totalChildQuantityCounted = 0;
          let totalChildQuantityForecast = 0;
          let totalChildAmountCounted = 0;
          let totalChildAmountForecast = 0;
          for (
            let indexChildElem = 0;
            indexChildElem < childElem.length;
            indexChildElem++
          ) {
            const element = childElem[indexChildElem];
            totalChildQuantityCounted += Number(element.quantityCounted);
            totalChildQuantityForecast += Number(element.quantityForecast);
            totalChildAmountCounted += Number(element.amountCounted);
            totalChildAmountForecast += Number(element.amountForecast);
          }
          return {
            ...elem,
            quantityCounted:
              childElem && childElem.length > 0
                ? totalChildQuantityCounted
                : elem.quantityCounted,
            quantityForecast:
              childElem && childElem.length > 0
                ? totalChildQuantityForecast
                : elem.quantityForecast,
            amountCounted:
              childElem && childElem.length > 0
                ? totalChildAmountCounted
                : elem.amountCounted,
            amountForecast:
              childElem && childElem.length > 0
                ? totalChildAmountForecast
                : elem.amountForecast,
          };
        });
      return result;
    },
  },
  async created() {
    Promise.all([
      this.getAnalyticsSettings({}),
      this.getAffairs({}),
      this.getMissions({}),
      this.getCollaborators({}),
      this.getCompanies({}),
    ]).then(() => {
      this.dataLoaded = true;
      this.headerLoaded = true;
    });

    let filter = this.userParametersList.find(
      (el) => el.key == "analyticalResultsFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getAnalyticsSettings",
      "getAffairCostings",
      "getAffairs",
      "getMissions",
      "getCollaborators",
      "getCompanies",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,
    comparer(a, b) {
      if (a.displayLabel < b.displayLabel) {
        return -1;
      }
      if (a.displayLabel > b.displayLabel) {
        return 1;
      }
      return 0;
    },
    updateViewMode(val) {
      this.selectedRows = [];

      if (this.viewMode !== val) {
        this.dataLoaded = false;
        this.headerData.forEach((cell) => {
          if (val == "counted") {
            if (cell.mode && cell.mode == "counted") {
              cell.visible = true;
            } else if (cell.mode && cell.mode !== "counted") {
              cell.visible = false;
            }
          } else if (val == "forecast") {
            if (cell.mode && cell.mode == "forecast") {
              cell.visible = true;
            } else if (cell.mode && cell.mode !== "forecast") {
              cell.visible = false;
            }
          }

          if (cell.columns && cell.columns.length > 0) {
            cell.columns.forEach((subCell) => {
              if (val == "counted") {
                if (subCell.mode && subCell.mode == "counted") {
                  subCell.visible = true;
                } else if (subCell.mode && subCell.mode !== "counted") {
                  subCell.visible = false;
                }
              } else if (val == "forecast") {
                if (subCell.mode && subCell.mode == "forecast") {
                  subCell.visible = true;
                } else if (subCell.mode && subCell.mode !== "forecast") {
                  subCell.visible = false;
                }
              } else {
                subCell.visible = true;
              }
            });
          }
        });
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }

      this.viewMode = val;
    },
    changeViewDates(dates, refreshViewMode = false) {
      if (dates.force) {
        this.setDates(dates);
      } else if (refreshViewMode) {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        this.setDates({ startDate: startDate, endDate: endDate });
      } else {
        let startDate = this.dateRangeDefault[0];
        let endDate = this.dateRangeDefault[1];
        if (this.dateRangeDefault[0] > dates.startDate) {
          startDate = dates.startDate;
        }
        if (this.dateRangeDefault[1] < dates.endDate) {
          endDate = dates.endDate;
        }
        if (
          this.dateRangeDefault[0] !== startDate &&
          this.dateRangeDefault[1] !== endDate
        ) {
          this.setDates({ startDate: startDate, endDate: endDate });
        }
      }
    },
    setHeaderData() {
      this.headerData = [
        {
          type: "number",
          field: "accountNumber",
          headerText: "Compte",
          width: 100,
          maxWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowEditing: true,
          visible: false,
        },
        {
          type: "string",
          field: "displayLabel",
          // template: "linkDocumentTemplate",
          headerText: "Libellé",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowEditing: true,
          visible: true,
        },
        {
          mode: "counted",
          visible:
            this.viewMode == "compare" || this.viewMode == "counted"
              ? true
              : false,
          headerText: "Réél",
          customCss: "text-primary",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "quantityCounted",
              label: "Quantité",
              headerText: "Quantité",
              textAlign: "center",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              valueAccessor: function (field, data, column) {
                return Number(data[field]).toFixed(2);
              },
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "amountCounted",
              label: "Montant",
              headerText: "Montant",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return formatCurrency(data[field]);
              },
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "percentCounted",
              label: "Pourcentage",
              headerText: "Pourcentage",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return formatNumber(data[field]) + " %";
              },
              customAttributes: {
                class: [
                  // "e-column-border-left-light",
                  "font-weight-bolder",
                  "e-column-border-right-light",
                ],
              },
            },
          ],
        },
        {
          mode: "forecast",
          visible:
            this.viewMode == "compare" || this.viewMode == "forecast"
              ? true
              : false,
          headerText: "Prévu",
          customCss: "text-warning",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "forecast",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "quantityForecast",
              label: "Quantité",
              headerText: "Quantité",
              textAlign: "center",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              valueAccessor: function (field, data, column) {
                return data[field]; // Number(data[field]).toFixed(2);
              },
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  // "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "forecast",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "amountForecast",
              label: "Montant",
              headerText: "Montant",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return formatCurrency(data[field]);
              },
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  // "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "forecast",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "percentForecast",
              label: "Pourcentage",
              headerText: "Pourcentage",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              valueAccessor: function (field, data, column) {
                return formatNumber(data[field]) + " %";
              },
              customAttributes: {
                class: ["e-column-border-left-light", "font-weight-bolder"],
              },
            },
          ],
        },
        {
          mode: "compare",
          visible: this.viewMode == "compare" ? true : false,
          headerText: "Ecarts",
          // customCss: "text-warning",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "compare",
              visible: this.viewMode == "compare" ? true : false,
              type: "number",
              field: "quantityGap",
              label: "Quantité",
              headerText: "Quantité",
              textAlign: "center",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              valueAccessor: function (field, data, column) {
                return formatNumber(
                  data["quantityCounted"] - data["quantityForecast"]
                ); // Number(data[field]).toFixed(2);
              },
              // template: this.colorCurrencyTemplate,
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "compare",
              visible: this.viewMode == "compare" ? true : false,
              type: "number",
              field: "amountGap",
              label: "Montant",
              headerText: "Montant",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              // template: this.colorCurrencyTemplate,
              valueAccessor: function (field, data, column) {
                return formatCurrency(
                  data["amountCounted"] - data["amountForecast"]
                );
              },
              customAttributes: {
                class: [
                  // "bg-light-grey",
                  // "text-primary",
                  "font-weight-bolder",
                  // "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "compare",
              visible: this.viewMode == "compare" ? true : false,
              type: "number",
              field: "percentForecast",
              label: "Pourcentage",
              headerText: "Pourcentage",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              // template: this.colorCurrencyTemplate,
              valueAccessor: function (field, data, column) {
                return (
                  formatNumber(
                    data["percentCounted"] - data["percentForecast"]
                  ) + " %"
                );
              },
              customAttributes: {
                class: ["e-column-border-left-light", "font-weight-bolder"],
              },
            },
          ],
        },

        {
          type: "number",
          field: "parentAccountNumber",
          filterTemplate: "parentAccountNumber",
          format: "formatNumber",
          headerText: "Compte Parent",
          // filterSettings: {}
          width: 100,
          maxWidth: 100,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListMapped,
            fields: {
              text: "accountNumber",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListMapped,
            },
            type: "dropdowntree",
          },
        },
      ];
    },
    async commandClick(args) {
      if (args.commandColumn.type === "Cancel") {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      } else if (args.commandColumn.type === "duplicate") {
        // const selectedRow =
        // this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords();
        // const newReport = { ...selectedRow[0] };
        // newReport.id = 0;
        // newReport.status = -1;
        // newReport.startDate = dayjs(newReport.startDate);
        // newReport.endDate = dayjs(newReport.endDate);
        // newReport.keyIndex = this.genererChaineAleatoire();
        // newReport.eventId = null;
        // newReport.interventionId = null;
        // this.dataFormReports.push(newReport);
        // this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
      } else if (args.commandColumn.type === "addRow") {
        // const selectedRow =
        // this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords();
        // const newReport = { ...selectedRow[0] };
        // newReport.id = 0;
        // newReport.status = -1;
        // newReport.startDate = dayjs(newReport.startDate);
        // newReport.endDate = dayjs(newReport.endDate);
        // newReport.keyIndex = this.genererChaineAleatoire();
        // newReport.missionId = null;
        // newReport.affairId = null;
        // newReport.payrollEventId = null;
        // newReport.duration = 0;
        // newReport.durationValue = 0;
        // newReport.message = null;
        // newReport.eventId = null;
        // newReport.interventionId = null;
        // this.dataFormReports.push(newReport);
        // this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
      }
    },

    setSelectedRowsIndexes(val) {
      this.selectedRowsIndexes = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    setAffairs(affairIds) {
      this.affairIds = affairIds;
    },
    setCollaborators(collaboratorIds) {
      this.collaboratorIds = collaboratorIds;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.currentDate = new Date(
          dayjs(dates.startDate).startOf("month").format("YYYY-MM-DD")
        );
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];

        await this.getAffairCostings({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    rowDataBound(args) {
      if (
        args.data["accountNumber"] == "VE" ||
        args.data["accountNumber"] == "CH"
      ) {
        args.row.style.background = "#0c357150";
        args.row.style.fontWeight = "600";
      } else if (args.data["accountNumber"] == "MB") {
        args.row.style.background = "#0c3571";
        args.row.style.fontWeight = "600";
        // args.row.children.style.color = '#fff';
        // var tdElements = document.querySelectorAll(args.row);
        // // Boucler à travers chaque élément <td> et appliquer le style color: #fff
        args.row.children.forEach(function (td) {
          td.style.color = "#ffffff";
        });
      }
    },
    async actionBegin(args) {
      if (args.rowData && args.rowData.status == 3) {
        args.cancel = true;
        return false;
      }
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        // for (
        //     var i = 0;
        //     i < this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns().length;
        //     i++
        // ) {
        //     if (
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[i].field ==
        //     "date"
        //     ) {
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[
        //         i
        //     ].visible = false;
        //     }
        //     if (
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[i].field ==
        //     "status"
        //     ) {
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[
        //         i
        //     ].visible = false;
        //     }
        // }
      }
      if (args.requestType === "save" || args.requestType == "delete") {
        // for (
        //     var i = 0;
        //     i < this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns().length;
        //     i++
        // ) {
        //     if (
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[i].field ==
        //     "date"
        //     ) {
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[
        //         i
        //     ].visible = true;
        //     }
        //     if (
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[i].field ==
        //     "status"
        //     ) {
        //     this.$refs.overviewtreegrid.$refs.overviewtreegrid.getColumns()[
        //         i
        //     ].visible = true;
        //     }
        // }
      }
      if (args.requestType === "save" && args.action === "edit") {
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewtreegrid.onFilterTextBoxChanged(search);
    },
    clickAction() {},
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewtreegrid.exportData(args.item.type);
      } else if (args.item.type == "columns") {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.openColumnChooser();
      }
    },
    refreshRow(val) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.setRowData(
        val.id,
        val
      );
    },
  },
};
</script>
